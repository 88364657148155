export const barChartMockData = {
    autosize: true,
    config: { responsive: true, staticPlot: true },
    data: [
        {
            hoverinfo: "none",
            marker: { color: "rgba(0,0,255)" },
            text: ["1201", "1363", "936", "862", "1059", "1111", "1000"],
            textfont: { size: "6" },
            textposition: "top",
            texttemplate: "%{text}<br>kWh",
            type: "bar",
            x: ["04/13", "04/14", "04/15", "04/16", "04/17", "04/18", "04/19"],
            y: ["1201", "1363", "936", "862", "1059", "1111", "1000"]
        }
    ],
    layout: {
        barcornerradius: 3,
        height: 380,
        margin: {
            b: 30,
            l: 0,
            pad: 0,
            r: 0,
            t: 0
        },
        showlegend: false,
        width: 270,
        xaxis: {
            visible: true
        },
        yaxis: {
            visible: false
        }
    },
    sliders: [{ pad: { r: 0 } }],
    style: { display: "inline-block", position: "relative" }
};
export const stackedBarChartMockData = {
    config: { staticPlot: true },
    data: [
        {
            marker: {
                color: "rgb(0, 102, 255)"
            },
            name: "Tracking",
            type: "bar",
            x: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28"
            ].map(Number),
            y: [
                "10",
                "300",
                "500",
                "600",
                "800",
                "10",
                "300",
                "500",
                "600",
                "800",
                "10",
                "300",
                "500",
                "600",
                "800",
                "10",
                "300",
                "500",
                "600",
                "800",
                "0",
                "300",
                "500",
                "600",
                "800",
                "10",
                "300",
                "500"
            ].map(Number)
        }
    ],
    layout: {
        barcornerradius: 3,
        barmode: "stack",
        legend: { orientation: "h", x: 0.5, xanchor: "center", y: 1.2 },
        width: 1000,
        xaxis: { type: "category" },
        yaxis: {
            ticksuffix: " kWh"
        }
    }
};
