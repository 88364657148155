export const googleMapContentDetails = [
    {
        assetId: "ELW00149",
        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
        location: {
            address: { streetAddress: "Hallenstraat 16, 5531 AB Bladel, Netherlands" },
            position: { coordinates: ["5.390544", "51.845509"].map(Number), type: "Point" }
        },
        make: "test",
        model: "CAT",
        serialNumber: "test123",
        severity: {
            severityLevelInfo: {
                code: 2
            }
        }
    },
    {
        assetId: "XNB20126",
        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
        location: {
            address: { streetAddress: "Zijpe 17, 4417 CN Hansweert, Netherlands" },
            position: { coordinates: ["5.404679", "51.847451"].map(Number), type: "Point" }
        },
        make: "test",
        model: "CAT",
        serialNumber: "test123",
        severity: {
            severityLevelInfo: {
                code: 2
            }
        }
    },
    {
        assetId: "XNB20241",
        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
        location: {
            address: { streetAddress: "Ophemertsedijk, 4007 PL Tiel, Netherlands" },
            position: { coordinates: ["5.416241", "51.869543"].map(Number), type: "Point" }
        },
        make: "387",
        model: "CAT",
        serialNumber: "test12345",
        severity: {
            severityLevelInfo: {
                code: 1
            }
        }
    },
    {
        assetId: "YNB20126",
        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
        location: {
            address: { streetAddress: "Hermoesestraat 20, 4062 PP Tiel, Netherlands" },
            position: { coordinates: ["5.412664", "51.858416"].map(Number), type: "Point" }
        },
        make: "157",
        model: "CAT",
        serialNumber: "test123456",
        severity: {
            severityLevelInfo: {
                code: 1
            }
        }
    },
    {
        assetId: "PLJ88345",
        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
        location: {
            address: { streetAddress: "Distriboulevard 17, 4782 PV Moerdijk, Netherlands" },
            position: { coordinates: ["5.308968", "51.829702"].map(Number), type: "Point" }
        },
        make: "BAB",
        model: "CAT",
        serialNumber: "test123456",
        severity: {
            severityLevelInfo: {
                code: 1
            }
        }
    },
    {
        assetId: "QLJ88345",
        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
        location: {
            address: { streetAddress: "Zennewijnenseweg 19, 4062 PL Tiel, Netherlands" },
            position: { coordinates: ["5.413564", "51.861416"].map(Number), type: "Point" }
        },
        make: "464",
        model: "CAT",
        serialNumber: "test123",
        severity: {
            severityLevelInfo: {
                code: 2
            }
        }
    },
    {
        assetId: "RLJ88345",
        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
        location: {
            address: { streetAddress: "Zennewijnenseweg 19, 4062 PL Tiel, Netherlands" },
            position: { coordinates: ["5.268666", "51.846507"].map(Number), type: "Point" }
        },
        make: "464",
        model: "CAT",
        serialNumber: "test123",
        severity: {
            severityLevelInfo: {
                code: 2
            }
        }
    },
    {
        assetId: "SLJ88345",
        iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
        location: {
            address: { streetAddress: "Zennewijnenseweg 19, 4062 PL Tiel, Netherlands" },
            position: { coordinates: ["5.300947", "51.828633"].map(Number), type: "Point" }
        },
        make: "464",
        model: "CAT",
        serialNumber: "test123",
        severity: {
            severityLevelInfo: {
                code: 2
            }
        }
    }
];
