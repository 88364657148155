import { Point } from "../../swagger/caas-v1/index";
export const AssetPortfolioData = [
    {
        assetId: "bbfe281c-dbd2-4939-b048-bf421d96663e",
        assetMetadata: {
            make: "CAT",
            model: "330",
            modelYear: 2022,
            productFamily: {
                code: "MHEX",
                iconUrl: "https://s7d2.scene7.com/is/image/Caterpillar/CM20170418-51089-64127",
                name: "MEDIUM HYDRAULIC EXCAVATOR"
            },
            serialNumber: "XNB20241"
        },
        assetName: "330 Z-Line P002 XNB20241",
        assetSerialNumber: "XNB20241",
        assetSourceType: "Machine",
        assetTransitDirection: "At Site",
        location: {
            position: {
                coordinates: ["51.462948", "3.9747078"].map(Number),
                type: Point.type.POINT
            }
        },
        soc: "95.0",
        socTrend: [
            {
                soc: 99.0,
                timestamp: "2024-11-05T18:40:00"
            },
            {
                soc: 100.0,
                timestamp: "2024-11-05T18:45:00"
            }
        ]
    },
    {
        assetId: "13bc13f6-6f05-4096-b058-81bd4550386c",
        assetName: "380-0400-0006 / v Oord Geldermalsen-Tiel",
        assetSerialNumber: "262343",
        assetSourceType: "ESS",
        assetTransitDirection: "At Chargesource",
        location: {
            position: {
                coordinates: ["51.454", "3.988"].map(Number),
                type: Point.type.POINT
            }
        },
        soc: "100.0"
    },
    {
        assetId: "185dc18e-f465-45f9-8018-c75677ea1add",
        assetName: "Rental 3",
        assetSourceType: "ESS",
        assetTransitDirection: "At Chargesource",
        location: {
            position: {
                coordinates: ["51.454346", "3.9877274"].map(Number),
                type: Point.type.POINT
            }
        },
        soc: "96.8421"
    },
    {
        assetId: "9b3a68e7-6f8c-49e3-a042-6fa3fb6baad2",
        assetName: "Van Oord 3",
        assetSourceType: "ESS",
        assetTransitDirection: "At site",
        location: {
            position: {
                coordinates: ["51.454", "3.988"].map(Number),
                type: Point.type.POINT
            }
        },
        soc: "100.0"
    }
];
